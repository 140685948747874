import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@repo/ui/components/ui/tooltip";
import { cn } from "@repo/ui/lib/utils";
import { Info } from 'lucide-react';

interface InfoIconProps {
  className?: string;
  text: string;
}

const InfoIcon: React.FC<InfoIconProps> = ({ className, text }) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Info className={cn("inline text-muted-foreground cursor-pointer", className)} />
        </TooltipTrigger>
        <TooltipContent className="max-w-80">
          <p>{text}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

InfoIcon.displayName = "InfoIcon";

export { InfoIcon };
