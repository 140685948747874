import { BookUser, ChartLine, FolderTree, LucideProps, MessagesSquare, User } from "lucide-react";
import React from "react";
import { Outlet, RouteObject } from "react-router-dom";
import { ErrorBoundary } from "../organisms";
import { RedditInteractions } from "../routes/lead-generation.interactions.reddit";
import { Account } from "../routes/profile.account";
import { Subscription } from "../routes/profile.subscription";
import { ProjectsCreate } from "../routes/projects.create";
import { ProjectsEdit } from "../routes/projects.edit.$id";
import { ProjectsList } from "../routes/projects.list";
import { RootIndex } from "../routes/root._index";

const DummyComponent = () => {
  return (
    <div>Hello World!</div>
  )
}

enum RouteStatus {
  Live = "live",
  Beta = "beta",
  WIP = "wip",
  UpgradeRequired = "upgrade",
}

interface BaseNonIndexRouteConfig {
  label: string;
  path: string;
  ElementComponent: React.ComponentType;
  noMenu?: boolean;
  subItems?: BaseRouteConfig[];
  status?: RouteStatus;
}

interface BaseIndexRouteConfig {
  index: true;
  ElementComponent: React.ComponentType;
}

export type BaseRouteConfig = BaseNonIndexRouteConfig | BaseIndexRouteConfig;
export type FirstLevelNonIndexRouteConfig = {
  IconComponent: React.ComponentType<LucideProps>;
} & BaseNonIndexRouteConfig;
export type FirstLevelIndexRouteConfig = BaseIndexRouteConfig;
type FirstLevelRouteConfig = FirstLevelNonIndexRouteConfig | FirstLevelIndexRouteConfig;

interface RoutesSection {
  sectionName: string | null;
  path: string;
  items: FirstLevelRouteConfig[];
}

export type RoutesSectionsConfig = RoutesSection[];

const routesSections: RoutesSectionsConfig = [
  {
    sectionName: null,
    path: "/",
    items: [
      {
        index: true,
        ElementComponent: RootIndex,
      },
      {
        label: "Projects",
        path: "projects",
        ElementComponent: ProjectsList,
        IconComponent: FolderTree,
        subItems: [
          {
            noMenu: true,
            label: "Create",
            path: "projects/create",
            ElementComponent: ProjectsCreate,
          },
          {
            noMenu: true,
            label: "Edit",
            path: "projects/edit/:projectId",
            ElementComponent: ProjectsEdit,
          },
        ]
      },
    ]
  },
  // {
  //   sectionName: "Overview",
  //   path: "overview",
  //   items: [
  //     {
  //       index: true,
  //       ElementComponent: RootIndex,
  //     },
  //     {
  //       label: "Dashboard",
  //       path: "overview/dashboard",
  //       ElementComponent: DummyComponent,
  //       IconComponent: ChartLine,
  //     },
  //   ]
  // },
  {
    sectionName: "Lead Generation",
    path: "lead-generation",
    items: [
      {
        index: true,
        ElementComponent: RootIndex,
      },
      {
        label: "Dashboard",
        path: "lead-generation/dashboard",
        ElementComponent: DummyComponent,
        IconComponent: ChartLine,
        status: RouteStatus.WIP,
      },
      {
        label: "Leads",
        path: "lead-generation/leads",
        ElementComponent: DummyComponent,
        IconComponent: BookUser,
        status: RouteStatus.WIP,
      },
      {
        label: "Interactions",
        path: "lead-generation/interactions",
        ElementComponent: DummyComponent,
        IconComponent: MessagesSquare,
        subItems: [
          {
            label: "Reddit",
            path: "lead-generation/interactions/reddit",
            ElementComponent: RedditInteractions,
          },
          {
            label: "X",
            path: "lead-generation/interactions/x",
            ElementComponent: DummyComponent,
            status: RouteStatus.WIP,
          },
          // {
          //   label: "LinkedIn",
          //   path: "lead-generation/interactions/linkedin",
          //   ElementComponent: DummyComponent,
          // },
        ]
      },
    ]
  },
  // {
  //   sectionName: "Content Marketing",
  //   path: "content-marketing",
  //   items: [
  //     {
  //       index: true,
  //       ElementComponent: RootIndex,
  //     },
  //     {
  //       label: "Dashboard",
  //       path: "content-marketing/dashboard",
  //       ElementComponent: DummyComponent,
  //       IconComponent: ChartLine,
  //     },
  //     {
  //       label: "Blog posts",
  //       path: "content-marketing/blog-posts",
  //       ElementComponent: DummyComponent,
  //       IconComponent: StickyNote,
  //     },
  //     {
  //       label: "Content repurpose",
  //       path: "content-marketing/content-repurpose",
  //       ElementComponent: DummyComponent,
  //       IconComponent: Recycle,
  //     },
  //   ]
  // },
  {
    sectionName: "User Management",
    path: "user-management",
    items: [
      {
        index: true,
        ElementComponent: RootIndex,
      },
      {
        label: "Profile",
        path: "user-management/profile",
        ElementComponent: Outlet,
        IconComponent: User,
        subItems: [
          {
            label: "Account",
            path: "user-management/profile/account",
            ElementComponent: Account,
          },
          {
            label: "Subscription",
            path: "user-management/profile/subscription",
            ElementComponent: Subscription,
          }
        ]
      }
    ]
  },
]

const createRouteObjects = (routes: Omit<BaseRouteConfig, "IconComponent">[]) => {
  return routes.map(route => {
    let routeObject: RouteObject
    if ("index" in route && route.index) {
      routeObject = {
        index: true,
        element: <route.ElementComponent />,
      };
    } else {
      const nonIndexRoute = route as BaseNonIndexRouteConfig;
      const { path } = nonIndexRoute;
      const subItems = "subItems" in nonIndexRoute ? nonIndexRoute.subItems : [];

      routeObject = {
        path,
        element: <nonIndexRoute.ElementComponent />,
        errorElement: <ErrorBoundary />
      };

      if (subItems) {
        routeObject.children = createRouteObjects(subItems);
      }
    }

    return routeObject;
  });
};

const routeObjects = createRouteObjects(
  routesSections.flatMap(
    ({ items }) => items.flatMap(
      item => "subItems" in item ?
        [item, ...(item.subItems || [])] :
        [item]
    )
  )
);

export { routeObjects, routesSections, RouteStatus };
