import { useAuth, useUser } from '@clerk/clerk-react';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@repo/ui/components/ui/tabs";
import { useQuery } from '@tanstack/react-query';
import { Archive, Check, Flame, Sparkles } from "lucide-react";
import { Link, useSearchParams } from 'react-router-dom';
import { PaginationWidget, RedditSubmissionCandidateCard, Spinner } from "../organisms/";
import { fetcher, serializeError } from '../services/api';
import { usePrefsStore } from '../store';

const buildTo = (
  searchParams: URLSearchParams,
  pageQueryParam: string,
  statusQueryParam: string,
  targetStatus: string
): string => {
  const updatedParams = new URLSearchParams(searchParams);
  updatedParams.set(pageQueryParam, "1");
  updatedParams.set(statusQueryParam, targetStatus);
  return `?${updatedParams.toString()}`;
}

const RedditInteractions = () => {
  const [searchParams] = useSearchParams();
  const statusParam = searchParams.get('status') || 'new';

  return (
    <div className="flex flex-col gap-8">

      <div className="flex justify-between">
        <h1 className="text-3xl font-semibold">Reddit opportunities</h1>
      </div>

      <Tabs value={statusParam} activationMode="manual" className="w-full">
        <TabsList className="grid w-fit grid-cols-4 border p-0 h-fit">
          <TabsTrigger className="min-w-36" value="new" asChild={true}>
            <Link to={buildTo(searchParams, "page", "status", "new")}>
              <span className="flex gap-2">
                <Sparkles className="size-5" />
                New
              </span>
            </Link>
          </TabsTrigger>
          <TabsTrigger className="min-w-36" value="hot" asChild={true}>
            <Link to={buildTo(searchParams, "page", "status", "hot")}>
              <span className="flex gap-2">
                <Flame className="size-5" />
                Hot
              </span>
            </Link>
          </TabsTrigger>
          <TabsTrigger className="min-w-36" value="done" asChild={true}>
            <Link to={buildTo(searchParams, "page", "status", "done")}>
              <span className="flex gap-2">
                <Check className="size-5" />
                Done
              </span>
            </Link>
          </TabsTrigger>
          <TabsTrigger className="min-w-36" value="archived" asChild={true}>
            <Link to={buildTo(searchParams, "page", "status", "archived")}>
              <span className="flex gap-2">
                <Archive className="size-5" />
                Archived
              </span>
            </Link>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="new">
          <RedditInteractionsTab submissionStatus="new" />
        </TabsContent>
        <TabsContent value="hot">
          <RedditInteractionsTab submissionStatus="hot" />
        </TabsContent>
        <TabsContent value="done">
          <RedditInteractionsTab submissionStatus="done" />
        </TabsContent>
        <TabsContent value="archived">
          <RedditInteractionsTab submissionStatus="archived" />
        </TabsContent>
      </Tabs>

    </div >
  )
}

interface RedditInteractionsTabProps {
  submissionStatus: string
}

const RedditInteractionsTab: React.FC<RedditInteractionsTabProps> = ({
  submissionStatus
}) => {
  const { getToken } = useAuth();
  const { isSignedIn } = useUser();
  const currentProject = usePrefsStore((state) => state.currentProject)
  const [searchParams] = useSearchParams();
  const pageParam = searchParams.get('page') || '1';
  const rawPage = parseInt(pageParam, 10);
  const page = isNaN(rawPage) ? 1 : rawPage;

  const { data, error, status } = useQuery({
    queryKey: [
      "get", "reddit", "submission-candidates",
      currentProject.id, submissionStatus, page
    ],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/reddit/submission-candidates", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          query: {
            project_id: currentProject.id,
            expand: ["submission", "submission.discovery", "submission.stats", "submission.owned_reply"],
            page_number: page,
            page_size: 10,
            status: submissionStatus,
            order_by: "score",
          }
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  if (status === "pending") {
    return (
      <Spinner />
    )
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-8">
        {
          data.data.map((rsc, i) => (
            <RedditSubmissionCandidateCard
              key={`reddit-card-${i}`}
              submissionCandidate={rsc}
            />
          ))
        }
      </div>

      {
        data.meta.total_pages > 0 && (
          <div className="mb-8">
            <PaginationWidget pageQueryParam="page" currentPage={page} maxPage={data.meta.total_pages} />
          </div>
        )
      }
    </div>

  )
}


export { RedditInteractions };
