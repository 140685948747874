import { useAuth, useUser } from '@clerk/clerk-react';
import { Badge } from "@repo/ui/components/ui/badge";
import { Button } from "@repo/ui/components/ui/button";
import { useQuery } from '@tanstack/react-query';
import { Pencil, Plus, Trash2 } from "lucide-react";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { Spinner } from "../organisms/";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fetcher, serializeError } from '../services/api';


const ProjectsList = () => {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const { isSignedIn } = useUser();

  const { data, error, status } = useQuery({
    queryKey: ["get", "projects"],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/projects", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  const projectDeletion = useMutation({
    mutationFn: async (projectId: number) => {
      const { data, error, response } = await fetcher.DELETE("/projects/{{project_id}}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            project_id: projectId,
          }
        },
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['get', 'projects'] });
      toast.success("Project deleted!",
        { description: data.data.name })
    },
    onError: (error) => {
      toast.error("Cannot delete project",
        { description: `${error}` })
    },
  })

  if (status === "pending") {
    return (
      <Spinner />
    )
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>
  }

  return (
    <div className="flex flex-col gap-8">

      <div className="flex justify-between">
        <h1 className="text-3xl font-semibold">Projects</h1>
        <Button
          size="sm"
          aria-label="Create project"
          asChild
        >
          <Link to="/projects/create">
            <div className="flex items-center gap-2">
              <Plus strokeWidth={2} className="size-5" />
              <span>New</span>
            </div>
          </Link>
        </Button>
      </div>

      <div className="flex flex-col gap-4">
        {
          data.data.map((project, i) => (
            <Card
              key={`card-${i}-${project.name}`}
              className="min-w-80 w-full"
            >
              <CardHeader>
                <div className="flex justify-between items-baseline">
                  <div className="flex gap-2">
                    <CardTitle>{project.name}</CardTitle>
                    <Badge
                      variant={project.status === "live" ? "success" : "secondary"}
                      className="text-xs"
                    >
                      {project.status.toUpperCase()}
                    </Badge>
                  </div>
                  <div className="flex gap-1">
                    <Button
                      variant="secondary"
                      size="icon"
                      aria-label="Edit project"
                      asChild
                    >
                      <Link to={`/projects/edit/${project.id}`}>
                        <Pencil className="size-5" />
                      </Link>
                    </Button>
                    <Button
                      variant="secondary"
                      size="icon"
                      aria-label="Delete project"
                      onClick={() => {
                        projectDeletion.mutate(project.id)
                      }}
                    >
                      <Trash2 className="size-5" />
                    </Button>
                  </div>
                </div>
                <CardDescription>{project.website || "No website"}</CardDescription>
              </CardHeader>
              <CardContent>
                <p className="whitespace-pre-line max-h-72 overflow-hidden">{project.description || "No description"}</p>
                {project.description && <span>...</span>}
              </CardContent>
              <CardFooter>
                <div className="flex">
                  {
                    project.keywords.length > 0 ?
                      project.keywords.map((kw, i) => (
                        <Badge key={`${kw}-${i}`} variant="secondary">{kw}</Badge>
                      )) : "No keywords"
                  }
                </div>
              </CardFooter>
            </Card>
          ))
        }
      </div>

    </div>
  )
}

export { ProjectsList };
