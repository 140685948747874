import { useAuth } from '@clerk/clerk-react';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@repo/ui/components/ui/avatar";
import { Badge } from "@repo/ui/components/ui/badge";
import { Button } from "@repo/ui/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import { Separator } from "@repo/ui/components/ui/separator";
import { Skeleton } from "@repo/ui/components/ui/skeleton";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { cva } from "class-variance-authority";
import { Archive, ArrowBigUp, Check, ChevronDown, ChevronUp, ExternalLink, Flame, MessageCircle, Scan, WandSparkles } from "lucide-react";
import { useState } from "react";
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import remarkGfm from 'remark-gfm';
import { toast } from "sonner";
import invariant from 'tiny-invariant';
import { fetcher, serializeError } from '../../services/api';
import { components } from '../../services/api/openapi';
import { usePrefsStore } from '../../store';

const timeAgo = (timestamp: string): string => {
  const now = new Date();
  const pastDate = new Date(timestamp);
  const seconds = Math.floor((now - pastDate) / 1000);

  if (seconds < 60) {
    return `${seconds}s`;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) {
    return `${minutes}m`;
  }

  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return `${hours}h`;
  }

  const days = Math.floor(hours / 24);
  if (days < 30) {
    return `${days}d`;
  }

  const months = Math.floor(days / 30);
  if (months < 12) {
    return `${months}mo`;
  }

  const years = Math.max(1, Math.floor(days / 365));
  return `${years}y`;
}

const isRecent = (timestamp: string): boolean => {
  const now = new Date();
  const pastDate = new Date(timestamp);
  const seconds = Math.floor((now - pastDate) / 1000);
  const hours = Math.floor(seconds / 3660);
  return hours <= 48;
}

interface RedditSubmissionCandidateCardProps {
  submissionCandidate: components["schemas"]["FullRedditSubmissionCandidate"]
}

const RedditSubmissionCandidateCard: React.FC<RedditSubmissionCandidateCardProps> = ({
  submissionCandidate
}) => {
  invariant(submissionCandidate.submission, "Unexpected missing Reddit submission")
  invariant(submissionCandidate.submission.discovery, "Unexpected missing Reddit submission.discovery")
  invariant(submissionCandidate.submission.stats, "Unexpected missing Reddit submission.stats")

  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const currentProject = usePrefsStore((state) => state.currentProject)
  const replyGeneration = useMutation({
    mutationFn: async (replyPayload: components["schemas"]["CreateRedditOwnedReplyCandidateRequest"]) => {
      const { data, error, response } = await fetcher.POST("/reddit/owned-reply-candidates", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        body: replyPayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get", "reddit", "submission-candidates", currentProject.id] });
      toast.success("Reply generated!")
    },
    onError: () => {
      toast.error("Cannot generate reply")
    },
  })

  const sortedStats = submissionCandidate.submission.stats
    .sort((a, b) => new Date(b.fetched_at) - new Date(a.fetched_at));
  const stats = sortedStats[0];

  let status = "idle";
  if (submissionCandidate.submission.owned_reply) {
    status = "done"
  } else {
    if (replyGeneration.variables?.parent_submission_id === submissionCandidate.submission_id) {
      if (replyGeneration.status === "error") {
        status = "idle"
      } else {
        status = "pending"
      }
    }
  }

  return (
    <div className="flex flex-col gap-2">
      <Card
        className="min-w-80 w-full"
      >
        <CardHeader>
          <RedditSubmissionCardHeader submissionCandidate={submissionCandidate} />
        </CardHeader>
        <CardContent>
          <RedditSubmissionCardContent
            text={submissionCandidate.submission.text}
            upvotes={stats?.upvotes || 0}
            comments={stats?.comments || 0}
          />
        </CardContent>
        <CardFooter>
          <div className="flex flex-col gap-4 w-full">
            <div className="flex flex-col bg-primary/10 w-full rounded-md p-4">
              <span className="text-lg font-semibold">Analysis</span>
              <ReactMarkdown
                className="max-w-none prose-sm prose italic"
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeHighlight]}
              >
                {submissionCandidate.summary}
              </ReactMarkdown>

            </div>
            {
              status === "idle" && submissionCandidate.status === "new" && (
                <div className="flex self-end">
                  <Button
                    aria-label="Generate reply"
                    className="flex gap-2"
                    onClick={() => {
                      replyGeneration.mutate({ parent_submission_id: submissionCandidate.submission_id })
                    }}
                  >
                    <WandSparkles className="size-5" />
                    <span>Generate reply</span>
                  </Button>
                </div>
              )
            }
          </div>
        </CardFooter>
      </Card>

      <RedditSubmissionReply
        status={status}
        text={submissionCandidate.submission.owned_reply?.text || ""}
      />

    </div>
  )
}

interface RedditSubmissionCardHeaderProps {
  submissionCandidate: components["schemas"]["FullRedditSubmissionCandidate"]
}

const RedditSubmissionCardHeader: React.FC<RedditSubmissionCardHeaderProps> = ({
  submissionCandidate
}) => {
  invariant(submissionCandidate.submission, "Unexpected missing Reddit submission")
  invariant(submissionCandidate.submission.discovery, "Unexpected missing Reddit submission.discovery")
  invariant(submissionCandidate.submission.stats, "Unexpected missing Reddit submission.stats")

  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const updateStatus = useMutation({
    mutationFn: async (updateStatusPayload: components["schemas"]["UpdateRedditSubmissionCandidateRequest"]) => {
      const { data, error, response } = await fetcher.PATCH("/reddit/submission-candidates/{reddit_submission_candidate_id}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            reddit_submission_candidate_id: submissionCandidate.id
          }
        },
        body: updateStatusPayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get", "reddit", "submission-candidates"] });
      toast.success("Status changed!")
    },
    onError: () => {
      toast.error("Cannot change status")
    },
  })

  const score = submissionCandidate.score;

  return (
    <>
      <div className="flex flex-col gap-4 justify-between items-center">

        <div className="flex justify-between w-full">

          <div className="flex gap-1">
            <div
              className="flex justify-center items-center bg-muted rounded-md pr-2"
            >
              <div className="w-10 h-10 flex justify-center items-center">
                <Scan className="size-5" />
              </div>
              <span className="text-xs">Discovered {timeAgo(submissionCandidate.submission.discovery?.finished_at || "")} ago</span>
            </div>
            <Badge
              variant={score < 50 ? "error" : score < 80 ? "warning" : "success"}
              className="text-md"
            >
              {score}
            </Badge>
          </div>

          <div className="flex gap-1">
            {submissionCandidate.status === "new" && (
              <>
                <Button
                  className="group hover:bg-success"
                  variant="secondary"
                  size="icon"
                  aria-label="Mark as done"
                  onClick={() => {
                    updateStatus.mutate({ status: "done" })
                  }}
                >
                  <Check className="size-5 group-hover:size-6 group-hover:text-success-foreground transition-all" />
                </Button>

                <Button
                  className="group hover:bg-warning"
                  variant="secondary"
                  size="icon"
                  aria-label="Archive"
                  onClick={() => {
                    updateStatus.mutate({ status: "archived" })
                  }}
                >
                  <Archive className="size-5 group-hover:size-6 group-hover:text-warning-foreground transition-all" />
                </Button>
              </>
            )}
            <Button
              className="group"
              variant="secondary"
              size="icon"
              aria-label="Go to external link"
              asChild
            >
              <a href={submissionCandidate.submission.link} target="_blank">
                <ExternalLink className="size-5 group-hover:size-6 transition-all" />
              </a>
            </Button>
          </div>

        </div>

        <div className="flex flex-col gap-1 w-full">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <Avatar>
                <AvatarImage src={submissionCandidate.submission.sub_avatar_link} alt={submissionCandidate.submission.sub} />
                <AvatarFallback>{submissionCandidate.submission.sub[0]}</AvatarFallback>
              </Avatar>
              <div className="flex flex-col">
                <span className="text-xs font-semibold">r/{submissionCandidate.submission.sub}</span>
                <div className="flex gap-2 h-5 text-xs items-center">
                  <span className="text-xs">u/{submissionCandidate.submission.author}</span>
                  ·
                  <span className="flex gap-2 items-center">
                    <span className="text-xs">{timeAgo(submissionCandidate.submission.submitted_at)}</span>
                  </span>
                  {
                    isRecent(submissionCandidate.submission.submitted_at) && (
                      <div
                        className="flex h-5 justify-center items-center bg-red-600/30 rounded-md"
                      >
                        <div className="w-5 h-5 flex justify-center items-center animate-ping">
                          <Flame strokeWidth={3} className="size-3 text-red-600 fill-orange-300" />
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <CardTitle className="text-lg">{submissionCandidate.submission.title}</CardTitle>
        </div>

      </div>
    </>
  )
}

RedditSubmissionCardHeader.displayName = "RedditSubmissionCardHeader";

interface RedditSubmissionCardContentProps {
  text: string;
  upvotes: number;
  comments: number;
}

const redditSubmissionCardContentVariants = cva(
  "",
  {
    variants: {
      isOpen: {
        false: "max-h-60 overflow-hidden",
        true: ""
      }
    },
    defaultVariants: {
      isOpen: false
    }
  }
)

const RedditSubmissionCardContent: React.FC<RedditSubmissionCardContentProps> = ({
  text, upvotes, comments
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ComponentIcon = isOpen ? ChevronUp : ChevronDown

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col relative">
          <div className={redditSubmissionCardContentVariants({ isOpen })}>
            <ReactMarkdown
              className="prose-sm max-w-none prose-p:my-2 prose-hr:my-6 prose-code:text-wrap"
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeHighlight]}
            >
              {text}
            </ReactMarkdown>
          </div>
          {!isOpen && (
            <div className="absolute bottom-0 flex flex-col items-center justify-center w-full h-20 bg-gradient-to-b from-transparent to-white"></div>
          )}
        </div>
        <div className="flex flex-col w-full items-center justify-center cursor-pointer">
          <ComponentIcon
            className="animate-bounce"
            onClick={() => setIsOpen((currentStatus) => !currentStatus)}
          />
        </div>
      </div>

      <div className="flex gap-2 items-center border w-fit px-2 py-1 rounded-xl text-sm">
        <div className="flex items-center gap-1">
          <ArrowBigUp className="size-5" /><span>{upvotes}</span>
        </div>
        <Separator orientation="vertical" className="h-6" />
        <div className="flex items-center gap-1">
          <MessageCircle className="size-5" /><span>{comments}</span>
        </div>
      </div>
    </div>
  )
}

RedditSubmissionCardContent.displayName = "RedditSubmissionCardContent"

interface RedditSubmissionReplyProps {
  status: string;
  text: string;
}

const RedditSubmissionReply: React.FC<RedditSubmissionReplyProps> = ({
  status, text
}) => {
  return (
    <>
      {
        status === "pending" && (
          <Skeleton className="h-[125px] min-w-80 w-10/12 self-end rounded-xl bg-success-foreground/30" />
        )
      }

      {
        status === "done" && (
          <Card
            className="min-w-80 w-10/12 self-end border-success-foreground bg-success"
          >
            <CardContent className="px-4 py-2">
              <ReactMarkdown
                className="prose-sm max-w-none prose-p:my-2 prose-hr:my-6 prose-code:text-wrap"
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeHighlight]}
              >
                {text}
              </ReactMarkdown>
            </CardContent>
          </Card>
        )
      }
    </>
  )
}

RedditSubmissionReply.displayName = "RedditSubmissionReply";

export { RedditSubmissionCandidateCard };
